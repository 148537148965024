/* @import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap'); */

.font-righteous {
    font-family: 'Righteous', sans-serif;
    text-align: right;
    margin-top: 4vw;
    font-size: 28px;
}
.px{

    padding-left: 10vw !important;
    padding-right: 10vw !important;
}



.mx{
    margin-left: 20px;
    margin-right: 20px;
}

.pr{
    padding-right: 5vw;
}

.p-text{
    font-size: 28px;
  
}

.ml-1{
    margin-left: 1vw;
}

.mt-7{
    margin-top: 7vw !important;
}

.main-heading{
    font-size: 28px;
    font-weight: bold;
}
.bold-25{
    font-weight: bold;
    font-size: 25px;
}
.normal-25{
font-size: 25px !important;
}

.bold-20{
    font-size: 20px;
    font-weight: bold;
}
.normal-20{
    font-size: 20px;
}

.bold-22{
    font-size: 22px;
    font-weight: bold;
}
.normal-22{
    font-size: 22px;
}

.image-alignment{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.strategy-containerOcr {
    position: relative;
    width: 100%;
    height: 100vh; /* Adjust as needed */
    background-image: url('../../res/images/Ocrbg.png'); /* use the imported image path */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center; /* Center align items vertically */
    justify-content: center;
    text-align: center;
    color: white;
    padding-top: 10vw; /* Add padding from the top */
}

.strategy-text-Ocr {
    position: absolute;
    top: 45%; /* Adjust the vertical position */
    left: 34%;
    text-align: left;
    transform: translate(-50%, -50%);
    color: rgb(0, 0, 0);
 
}
.Airline{
    /* font-family: Roboto !important; */
    /* font-weight: bold; */
    font-size: 86px;
    font-weight: bold;  
    color: white
}
.GenAiSubheading{
    font-size: 28px;
    /* font-family: Roboto !important; */
    color: #212429;
}


.step-involved{
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 30px !important;

}


.center-person{
    display: flex;
    align-items: center;
    justify-content: center;
}

.elm-solution{
    font-weight: bold;
    font-family: "Merriweather";
    font-size: 44px;
}


.elm-text{
    font-size: 22px;
    /* font-family: 'Roboto'; */
}
.industry{
    text-decoration: underline;
    font-weight: bold;
    font-family: 'merriweather';
}

.application{
    font-size: 30px;
    font-weight: lighter;
}
.one {
    background: linear-gradient(to right, #ff7e5f, #feb47b); /* Example gradient from orange to light orange */
    border-radius: 4px;
    width: 30px;
    height: 30px;
}

.card-height {
    height: 203px; /* Adjust based on your design */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 8px;
  }
  
  .card-body {
    padding: 20px;
  }
  
  .step-number {
    background: linear-gradient(to right, #1e3c72, #2a5298); /* Example gradient */
    border: none;
    border-radius: 8px;
    width: 40px;
    height: 40px;
    color: white;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .card-title {
    font-size: 18px;
    font-weight: bold;
  }
  
  .card-text {
    font-size: 16px;
  }
  
  .d-flex {
    display: flex;
    align-items: center;
  }
  .generativeAi{
    /* font-family: Regular; */
    font-size: 26px;
    color: #212429;
   
  }
  .efficiency-p{
    font-size: 41px;
    /* font-family: Roboto; */ 
  }
  .c-50{
    color: #2EBDE6;
  }
 
  .icon-p{
    font-size: 14px;
    margin-left: 0.25rem;
  }

  .ocr-btn{
    padding: 1vw;
    border-radius: 30px;
    font-size: 20px;
    background-color: transparent;
    opacity: 1;
    color: white;
  }
  .misguided {
    font-size: 99px;
    font-weight: 900;
    line-height: 1; /* Adjust this value to remove spaces between lines */
    padding-left: 30px !important;
    margin: 0; }

  .pl-30{
    padding-left: 30px;
  }

  .bordertoplulusar{
    margin-top: 60px;
    margin-bottom: 10px;
  }

  .iconhw{
    height: 90px;
    width: 90px;
  }

  .disparate{
    font-size: 37px;
    font-family: "merriweather";
text-align: right;
  }

  .Retail{
    font-size: 41px;
    font-family: "merriweather";
  }

  .cheading{
color: #603983;
font-size: 30px;
font-family: "merriweather";
  }

  .impact{
    font-size: 41px;
    font-family: "merriweather";
    justify-content: right;
    display: flex;
    padding: 0px !important;
    margin: 0 !important;
  }

  .f-26{
    font-size: 26px;
  
  }
  .f-20{
    font-size: 20px;
  }
  .custom-modal .modal-dialog {
    max-width: 95%;
  }
  