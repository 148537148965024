
.li-text{
  font-size: 43px;
  font-weight: bolder;
  font-family: 'montserrat';
}
.email-marketing{
  font-size: 64px;
  font-family: 'merriweather';
  color: #1E1E1E;
  line-height: 0.9;
  margin-bottom: 2vw;
}
.email-p{
  font-size: 23px;
  color: #1E1E1E;
  padding-top: 2vw;
  border-top: 8px solid #CE3C3C;
}
.industry-li{
  color: #0A66C2;
  font-size: 120px;
  font-weight: 400;
}
.app-li{
font-size: 57px;
color: #1E1E1E;
font-weight: 400;
}
.cp{
  font-size: 18px;
  font-weight: 800;
  color: #0A66C2;
  font-family: 'montserrat';
}
.cp-sub{
  color: #1E1E1E;
  font-size: 16px;
}


.px{

    padding-left: 10vw !important;
    padding-right: 10vw !important;
}
.pr-10{
  padding-right: 10vw !important;
}

.mx{
    margin-left: 20px;
    margin-right: 20px;
}

.pr{
    padding-right: 5vw;
}

.p-text{
    font-size: 28px;
  
}

.ml-1{
    margin-left: 1vw;
}

.mt-7{
    margin-top: 7vw !important;
}

.main-heading{
    font-size: 28px;
    font-weight: bold;
}
.bold-25{
    font-weight: bold;
    font-size: 25px;
}
.normal-25{
font-size: 25px !important;
}

.bold-20{
    font-size: 20px;
    font-weight: bold;
}
.bold-22{
    font-size: 22px;
    font-weight: bold;
}
.normal-22{
    font-size: 22px;
}

.image-alignment{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.strategy-container-levis {
    position: relative;
    width: 100%;
    height: 100vh; /* Adjust as needed */
    background-image: url('../../res/images/Levisbg.png'); /* use the imported image path */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center; /* Center align items vertically */
    justify-content: center;
    text-align: center;
    color: white;
    padding-top: 10vw; /* Add padding from the top */
}

.strategy-text-levis {
    position: absolute;
    top: 40%; /* Adjust the vertical position */
    left: 32%;
    text-align: left;
    transform: translate(-50%, -50%);
    color: rgb(255, 255, 255);
    width: 50%;
 
}
.levis-heading{
  font-weight: 900;
  font-size: 44px;
  font-family: 'montserrat';
}
.bullet{
  font-size: 50px;
  font-weight: bold;
  font-family: "montserrat";
}
.b-sub{
  font-size: 50px;
  /* font-family: 'Roboto'; */
  font-style: italic;
  color: #0A197E;
}
.b-b-sub{
  font-size: 20px;
  color: #1E1E1E;
}
.h-p{
  margin-left: 1.5vw;
  line-height: 0.5;
}
.levis-p{
  font-size: 18px;
  font-weight: 200 !important;
  font-family: 'montserrat';

}

.levis-container{
  padding: 15px;
  background-color: #1E1E1E;
}
.fleet-safety{
    /* font-family: montserrat !important; */
    font-weight: bold;
    font-size: 44px;  
    color: #ffffff; 
}
.ft-subheading{
    font-size: 30px;
    color: white;
}
.ft-s-s-heading{
    font-size: 20px;
color: white;
}
.GenAiSubheading{
    font-size: 28px;
    /* font-family: Roboto !important; */
    color: #212429;
}


.step-involved{
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 30px !important;

}


.center-person{
    display: flex;
    align-items: center;
    justify-content: center;
}

.elm-solution{
    font-weight: bold;
    font-family: "Merriweather";
    font-size: 44px;
}


.elm-text{
    font-size: 22px;
    /* font-family: 'Roboto'; */
}
.industry{
    text-decoration: underline;
    font-weight: bold;
    font-family: 'merriweather';
}

.application{
    font-size: 30px;
    font-weight: lighter;
}
.one {
    background: linear-gradient(to right, #ff7e5f, #feb47b); /* Example gradient from orange to light orange */
    border-radius: 4px;
    width: 30px;
    height: 30px;
}

.card-height {
    height: 203px; /* Adjust based on your design */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 8px;
  }
  
  .card-body {
    padding: 20px;
  }
  
  .step-number {
    background: linear-gradient(to right, #1e3c72, #2a5298); /* Example gradient */
    border: none;
    border-radius: 8px;
    width: 40px;
    height: 40px;
    color: white;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .card-title {
    font-size: 18px;
    font-weight: bold;
  }
  
  .card-text {
    font-size: 16px;
  }
  
  .d-flex {
    display: flex;
    align-items: center;
  }
  .generativeAi{
    /* font-family: Regular; */
    font-size: 26px;
    color: #212429;
   
  }
  .efficiency-p{
    font-size: 41px;
    /* font-family: Roboto; */ 
  }
  .c-50{
    color: #2EBDE6;
  }
 
  .icon-p{
    font-size: 14px;
    margin-left: 0.25rem;
  }

  .ls{
    color: #1E1E1E;
    font-size: 30px;
    font-family: 'montserrat';
  }

  .iot-based{
    background-color: rgb(233, 233, 233);
    padding-top: 7vw;
    padding-bottom: 10vw;
  }

  .hb{
   color: #1E1E1E;
   font-family: 'merriweather';
   font-size: 30px;
  }
  .sc{
    font-weight: bolder;
    margin-bottom: 1px;
    font-size: 16px;
    font-family: 'Montserrat';
    color: #1E1E1E;
  }
  .hb-sub{
    color: #1E1E1E;
    font-size: 18px;
    font-family: 'montserrat';  }