/* @import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap'); */

.font-righteous {
    font-family: 'Righteous', sans-serif;
    text-align: right;
    margin-top: 4vw;
    font-size: 28px;
}

.PSW {
    background-image: url('../../res/images/greendots.png');
    background-repeat: no-repeat;
   
    padding: 20px; 
  
}

.px{

    padding-left: 10vw !important;
    padding-right: 10vw !important;
}

.mx{
    margin-left: 20px;
    margin-right: 20px;
}

.pr{
    padding-right: 5vw;
}

.p-text{
    font-size: 28px;
  
}

.ml-1{
    margin-left: 1vw;
}

.mt-7{
    margin-top: 7vw !important;
}

.main-heading{
    font-size: 28px;
    font-weight: bold;
}
.bold-25{
    font-weight: bold;
    font-size: 25px;
}
.normal-25{
font-size: 25px !important;
}

.bold-20{
    font-size: 20px;
    font-weight: bold;
}
.normal-20{
    font-size: 20px;
}

.bold-22{
    font-size: 22px;
    font-weight: bold;
}
.normal-22{
    font-size: 22px;
}

.image-alignment{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.strategy-containerElm {
    position: relative;
    width: 100%;
    height: 100vh; /* Adjust as needed */
    background-image: url('../../res/images/Elm.jpeg'); /* use the imported image path */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center; /* Center align items vertically */
    justify-content: center;
    text-align: center;
    color: white;
    padding-top: 10vw; /* Add padding from the top */
}

.strategy-text {
    position: absolute;
    top: 40%; /* Adjust the vertical position */
    left: 40%;
    text-align: left;
    transform: translate(-50%, -50%);
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}
.cvcc{
    font-family: merriweather !important;
    font-size: 66px;
    font-weight: bold;   
}
.ElmSubheading{
    font-size: 26px;
    font-weight: bold;
    font-family: merriweather;
}


.step-involved{
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 30px !important;

}


.center-person{
    display: flex;
    align-items: center;
    justify-content: center;
}

.elm-solution{
    font-weight: bold;
    font-family: "Merriweather";
    font-size: 44px;
}


.elm-text{
    font-size: 22px;
    /* font-family: 'Roboto'; */
}
.industry{
    text-decoration: underline;
    font-weight: bold;
    font-family: 'merriweather';
}

.application{
    font-size: 30px;
    font-weight: lighter;
}
.one {
    background: linear-gradient(to right, #ff7e5f, #feb47b); /* Example gradient from orange to light orange */
    border-radius: 4px;
    width: 30px;
    height: 30px;
}

.card-height {
    height: 203px; /* Adjust based on your design */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 8px;
  }
  
  .card-body {
    padding: 20px;
  }
  
  .step-number {
    background: linear-gradient(to right, #1e3c72, #2a5298); /* Example gradient */
    border: none;
    border-radius: 8px;
    width: 40px;
    height: 40px;
    color: white;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .card-title {
    font-size: 18px;
    font-weight: bold;
  }
  
  .card-text {
    font-size: 16px;
  }
  
  .d-flex {
    display: flex;
    align-items: center;
  }


