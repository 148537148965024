/* navbar.css */

.navbar-nav .nav-item:hover .dropdown-menu {
    display: block;

  }



  
  .dropdown-mega {
    position: relative;
    cursor: pointer;
  }
  
  .dropdown-menu {
    display: none;
    position: absolute;
    top: 100%; 
    left: 0;
    z-index: 1000;
    width: 100%;
    background-color: #212429;
  }
  
  /* .dropdown-mega:hover .dropdown-menu {
    display: block;
  } */
  
  .mega-content {
    padding: 20px;
    background-color: #212429;
    width: 100%;
  }
  
  .list-group-item:hover {
    background-color: #212429;
  }
  


  /* GlowButton.css */

.glow-button {
  background-color: #1a1a1a; /* Dark background */
  color: white; /* Text color */
  border: none; /* No border */
  border-radius: 20px; /* Rounded corners */
  padding: 8px 15px; /* Padding */
  font-size: 1.2rem; /* Font size */
font-weight: 500;
  cursor: pointer; /* Pointer cursor on hover */
  transition: all 0.3s ease; /* Smooth transition for effects */
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5), /* Outer glow */
              0 0 20px rgba(255, 255, 255, 0.5), /* Outer glow */
              0 0 30px rgba(255, 255, 255, 0.5); /* Outer glow */
}

/* Hover effect */
.glow-button:hover {
  background-color: #0e122d; /* Slightly lighter dark on hover */
  box-shadow: 0 0 20px rgba(255, 255, 255, 1), /* Brighter outer glow */
              0 0 30px rgba(255, 255, 255, 1), /* Brighter outer glow */
              0 0 40px rgba(255, 255, 255, 1); /* Brighter outer glow */
}

/* Focus effect */
.glow-button:focus {
  outline: none; /* Remove outline */
}

/* Active effect */
.glow-button:active {
  transform: scale(0.95); /* Slightly shrink on click */
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5); /* Reduce glow on click */
}
