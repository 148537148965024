.tag-button {
  border: 2px solid #ff3b3b; /* Red border */
  border-radius: 20px; /* Rounded edges to create pill shape */
  padding: 5px 15px; /* Padding for spacing inside the button */
  background-color: #fef4f2; /* No background */
  color: black; /* Black text color */
  font-size: 14px; /* Adjust font size */
  cursor: pointer; /* Changes cursor to pointer on hover */
  outline: none; /* Removes the outline when clicked */
}

.today{
color: #ED4E68;
font-size: 47px;
font-family: 'montserrat';
}
.fast-paced {
  color: #ED4E68;
  font-size: 57px;
  font-weight: 900;
  font-style: italic; /* Add this to make the text italic */
  font-family: 'Montserrat', sans-serif; /* Ensure proper font-family */
}
.business-world{
color: #ED4E68;
font-family: 'merriweather';
font-size: 68px;
}

.dpp{
  color: #122891;
  font-family: 'montserrat';
  font-size: 47px;
}
.dpp-container{
  padding-left: 7vw;
}
  


.strategy-container-dp-stack {
    position: relative;
    width: 100%;
    height: 100vh; /* Adjust as needed */
    background-image: url('../../res/images/DpStack120.png'); /* use the imported image path */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center; /* Center align items vertically */
    justify-content: center;
    text-align: center;
    color: white;
    padding-top: 10vw; /* Add padding from the top */
}

.strategy-text-ft {
    position: absolute;
    top: 50%; /* Adjust the vertical position */
    left: 37%;
    text-align: left;
    transform: translate(-50%, -50%);
    color: rgb(255, 255, 255);
    width: 50%;
    line-height: 1;
 
}
.fleet-safety{
    /* font-family: montserrat !important; */
    font-weight: bold;
    font-size: 44px;  
    color: #ffffff; 
}
.ft-subheading{
    font-size: 30px;
    color: white;
}
.ft-s-s-heading{
    font-size: 20px;
color: white;
}
.GenAiSubheading{
    font-size: 28px;
    /* font-family: Roboto !important; */
    color: #212429;
}


.step-involved{
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 30px !important;

}


.center-person{
    display: flex;
    align-items: center;
    justify-content: center;
}

.elm-solution{
    font-weight: bold;
    font-family: "Merriweather";
    font-size: 44px;
}


.elm-text{
    font-size: 22px;
    /* font-family: 'Roboto'; */
}
.industry{
    text-decoration: underline;
    font-weight: bold;
    font-family: 'merriweather';
}

.application{
    font-size: 30px;
    font-weight: lighter;
}
.one {
    background: linear-gradient(to right, #ff7e5f, #feb47b); /* Example gradient from orange to light orange */
    border-radius: 4px;
    width: 30px;
    height: 30px;
}

.card-height {
    height: 203px; /* Adjust based on your design */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 8px;
  }
  
  .card-body {
    padding: 20px;
  }
  
  .step-number {
    background: linear-gradient(to right, #1e3c72, #2a5298); /* Example gradient */
    border: none;
    border-radius: 8px;
    width: 40px;
    height: 40px;
    color: white;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .card-title {
    font-size: 18px;
    font-weight: bold;
  }
  
  .card-text {
    font-size: 16px;
  }
  
  .d-flex {
    display: flex;
    align-items: center;
  }
  .generativeAi{
    /* font-family: Regular; */
    font-size: 26px;
    color: #212429;
   
  }
  .efficiency-p{
    font-size: 41px;
    /* font-family: Roboto; */ 
  }
  .c-50{
    color: #2EBDE6;
  }
 
  .icon-p{
    font-size: 14px;
    margin-left: 0.25rem;
  }

  .edge-cut{
    color: #4ACE6D;
    font-size: 23px;
    font-weight: 600;
    font-family: "Montserrat";
  }

  .iot-based{
    background-color: rgb(233, 233, 233);
    padding-top: 7vw;
    padding-bottom: 10vw;
  }
  .f-section{
    background-color: rgb(233, 233, 233);
    padding-top: 7vw;
    padding-bottom: 5vw;
  }

  .fi-stack{
    color: 1E1E1E;
    font-size: 36px;
    font-weight: 200;
    font-family: "montserrat";
  }
  .sc{
    font-weight: bolder;
    margin-bottom: 1px;
    font-size: 16px;
    font-family: 'Montserrat';
    color: #1E1E1E;
  }
  .sc-sub{
    color: #1E1E1E;
    font-size: 16px;
    font-weight: 400;
  }
  .dp-unveils {
    font-size: 25px;
    font-family: 'Montserrat', sans-serif;
    font-weight: lighter !important;
    text-decoration: underline;
    margin-bottom: 1vw;
  }
  
  .Revolutionizing {
    font-size: 70px;
    font-family: 'Regular', sans-serif; /* Ensure you load or use the correct 'Regular' font */
    font-weight: lighter; /* To match the lighter font-weight */
  }
  
  .Corporate {
    font-family: 'Merriweather', serif; /* Make sure the font is available */
    font-size: 115px;
  }
  
  .k-sharing {
    font-family: 'Baumans'; /* Ensure 'Baumans' font is properly imported */
    font-size: 70px;
  }
  .ml-4{
margin-left: 4vw;
  }
  .ssc{
    font-size: 14px;
    color: #626262;
  }
  .f-14{
    font-size: 14px;
  }