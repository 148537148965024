.flex-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;

  margin-top: 30px;
}

.Bottomnav {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}

h2 {
  color: white;
  margin-left: 45px;
  margin-top: 10px;
}
.output {
  box-shadow: 5px 10px 18px #7b4982;
  border-radius: 15px;
  border: #3a4581;
  border-style: solid;
  border-width: 2px;
  background-color: #6a6a7b;
  width: 160px;
  height: 70px;
  margin: 10px;
  text-align: center;
  line-height: 30px;
  color: white;
  font-size: 17px;
  font-weight: bold;

  opacity: 0.8;
}

.input {
  width: 12vw;
  height: 50px;
  border-radius: 5px;
  text-align: center;

  color: rgb(0, 0, 0);
  font-size: 19px;
}

.td {
  font-weight: bold;
  font-size: 20px;
  color: grey;
}

.forecast {
  background-image: linear-gradient(to bottom right, #1cbdcb, #0a197e);
  width: 180px;
  height: 65px;
  border-radius: 5px;

  border-style: solid;
  color: white;
  font-size: 17px;
  font-weight: normal;
  margin-top: 63px;
}

.vtt {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}

.boxdesign {
  text-align: center;
  border: 2px solid rgb(150, 152, 147);
  border-radius: 20px;
  padding: 45px;
  background-color: black;
  opacity: 0.9;
}
.rpaleftbtn {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 65%;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 70px;
  padding-bottom: 70px;

  text-align: center;
  border-radius: 12px;
  font-size: 28px;
  font-weight: bolder;
  color: white;
  border: solid 3px #265f92;
  background-color: #43434a;
  box-shadow: 5px 10px 18px #7b4982;
  opacity: 0.8;
}
.rparightbtn {
  cursor: pointer;
  justify-content: center;
  align-items: center;

  padding-left: 110px;
  padding-right: 110px;
  padding-top: 66px;
  padding-bottom: 65px;
  text-align: center;
  border-radius: 12px;

  font-size: 28px;
  font-weight: bolder;
  color: white;
  border: solid 3px #265f92;
  background-color: #43434a;
  box-shadow: 5px 10px 18px #7b4982;
  opacity: 0.9;
}
.Rpaboxdesign {
  text-align: center;
  border: 1px solid rgba(18, 17, 53, 255);
  border-radius: 20px;
  padding: 120px;
  background-color: rgba(18, 17, 53, 255);
  opacity: 0.9;
}
.buttonstyle {
  align-items: center;
  text-align: center;
  border-radius: 100rem;
  height: 90px;
  cursor: pointer;
  justify-content: center;
  width: 210px;
  padding: 10px 15px;
  margin: 10px 15px;
  font-size: 18px;
  font-weight: bolder;
  border-radius: 12px;
  color: white;
  border: solid 3px #265f92;
  background-color: #43434a;
  box-shadow: 5px 10px 18px #7b4982;
  opacity: 0.9;
}
.card {
  border-radius: 20px;
}

.h3style {
  color: white;
}
.pstyle {
  color: white;
  padding-right: 20px;

  font-weight: bold;
  font-size: 20px;
}

/* mega navbar */

@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;900&display=swap");
/* Remove the default dropdown icon */
.nav-link.no-icon::after {
  display: none;
}

button:focus,
.navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

header {
  background-color: #212429;
}

.navbar-brand {
  font-size: 40px;
  font-weight: 700;
  color: #0aeded;
  -webkit-text-stroke: #fff 1px;
}

.navbar-brand:hover {
  color: #3f3b51;
}

.navbar-nav .nav-item {
  margin: 0 10px;
}

.navbar-nav .nav-link {
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #fff !important;
  display: inline-block !important;
}
.dropdown-menu {
  background-color: #212429 !important;
  border: none !important;
  border-radius: 0px !important;
  margin-top: 0px !important;
}
.dropdown-menu .dropdown-item {
  margin-bottom: 10px;
}

.dropdown-menu .dropdown-item:hover {
  background-color: #008489;
  color: #fff;
}

.dropdown-mega .dropdown-menu {
  width: 100%;
}

.dropdown-mega h5 {
  padding-bottom: 12px;
  margin: 0;
  color: white;
  font-weight: 100;
  font-size: large;
}

.list-group-item {
  font-size: 16px;
  color: white !important;
  background-color: #212429 !important;
  border: 0;
  /* border-bottom: 1px solid rgba(0, 0, 0, .125); */
  padding: 12px 0 !important;
}

.list-group-item:hover {
  color: #008489;
}

.btn {
}

.carousel-caption {
  left: 7% !important;
  right: 7% !important;
  text-align: left;
}

/* Responsive */
@media (max-width: 991.5px) {
  .navbar-brand {
    font-size: 30px;
  }

  .navbar-nav .nav-item {
    margin: 5px 10px;
  }

  form {
    margin: 30px 0;
  }
}

.image-container {
  position: relative;
  height: 100%;
  width: 100%;
}

.card-img-top {
  width: 100%;
  height: auto;
  display: block;
}

.image-text {
  position: absolute;
  bottom: 30px;
  left: 10px;
  color: white;
  font-family: "Times New Roman", Times, serif;
  padding: 5px;
  font-size: 30px;
  border-radius: 3px;
}



.card-img-top {
  width: 100%;
  height: auto;
  display: block;
}
.clr{
  color: #0a197e;
}
