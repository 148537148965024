/* @import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap'); */

.font-righteous {
    font-family: 'Righteous', sans-serif;
    text-align: right;
    margin-top: 4vw;
    font-size: 28px;
}

.PSW {
    background-image: url('../../res/images/greendots.png');
    background-repeat: no-repeat;
   
    padding: 20px; 
  
}

.usimg {
    background-image: url('../../res/images/understandsection.png');
    background-size: cover; /* Cover the entire div */
    background-position: center; /* Center the image */
    height: 464px; /* Adjust height as needed */


  }

.bg-img{
  background-color: rgb(68,131,116);

}

.px{

    padding-left: 10vw !important;
    padding-right: 10vw !important;
}

.mx{
    margin-left: 20px;
    margin-right: 20px;
}

.pr{
    padding-right: 5vw;
}

.p-text{
    font-size: 28px;
  
}

.ml-1{
    margin-left: 1vw;
}

.mt-7{
    margin-top: 7vw !important;
}

.main-heading{
    font-size: 28px;
    font-weight: bold;
}
.bold-25{
    font-weight: bold;
    font-size: 25px;
}
.normal-25{
font-size: 25px !important;
}

.bold-20{
    font-size: 20px;
    font-weight: bold;
}
.normal-20{
    font-size: 20px;
}

.bold-22{
    font-size: 22px;
    font-weight: bold;
}
.normal-22{
    font-size: 22px;
}

.image-alignment{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.strategy-container {
    position: relative;
    width: 100%;
    height: 100vh; /* Adjust as needed */
    background-image: url('../../res/images/strategy.png'); /* use the imported image path */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: flex-start; /* Align items to the top */
    justify-content: center;
    text-align: center;
    color: white;
    padding-top: 10vw; /* Add padding from the top */
}



.bt{
    border-top: solid 1px grey;
}

.plrb{
padding-left: 1vw;
padding-right: 1vw;
padding-bottom: 1vw;
}
