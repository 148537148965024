
.strategy-container-mktg {
  position: relative;
  width: 100%;
  height: 100vh; /* Adjust as needed */
  background-image: url('../../res/images/mktnbg.png'); /* use the imported image path */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center; /* Center align items vertically */
  justify-content: center;
  text-align: center;
  color: white;
  padding-top: 10vw; /* Add padding from the top */
}

.strategy-text-mktg {
  position: absolute;
  top: 46%; /* Adjust the vertical position */
  left: 32%;
  text-align: left;
  transform: translate(-50%, -50%);
  color: rgb(255, 255, 255);
  width: 40%;

}
.mktg-main-heading{
font-weight: 700;
font-size: 42px;
font-family: 'montserrat';
}
.mktg-subheading{
  font-size: 20px;
  font-family: 'montserrat';
  font-weight: lighter;

}
.problem{
  font-size: 20px;
  font-weight: 800;
  font-family: 'montserrat';
  border-bottom: 4px solid #383E5D;
}
.pb-subheading{
  font-size: 20px;
}